import parsePhoneNumber, { PhoneNumber } from "libphonenumber-js/max";

const filters = {
  date(value: string): string | null {
    const date = new Date(value);
    if (isNaN(date.getTime())) {
      return null;
    }
    return date.toLocaleDateString();
  },
  time(value: string): string | null {
    const date = new Date(value);
    if (isNaN(date.getTime())) {
      return null;
    }
    return date.toLocaleTimeString();
  },
  dateTime(value: string): string | null {
    const date = new Date(value);
    if (isNaN(date.getTime())) {
      return null;
    }
    return date.toLocaleString();
  },
  phoneNumber(value: string | null): string | null {
    if (value === null) {
      return null;
    }
    const phoneNumber: PhoneNumber | undefined = parsePhoneNumber(value);
    if (phoneNumber === undefined) {
      return null;
    }
    return phoneNumber.formatInternational();
  },
};

export default filters;
